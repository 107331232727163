import Swal from 'sweetalert2';
import jwt from 'jwt-simple';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { store } from '../store/store';
import { SHOW_CONTACT_SALES_MODAL, SHOW_ERROR_LOG_REPORT_MODAL } from '../store/Reducers/constants';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: toast => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const showToaster = (message, type, timer = 3000, showCloseButton = false) => {
  Toast.fire({
    timer,
    icon: type,
    title: message,
    showCloseButton,
  });
};

export const closeToaster = () => Swal.close();

export const ShowAlertBox = (title, message, type) => {
  Swal.fire(title, message, type);

  // Swal.fire({
  //     // position: 'top-end',
  //     icon: type,
  //     title: message,
  //     showConfirmButton: false,
  //     timer: 1500
  // })
};
export const getUserItem = type => {
  const getUserInfo = decodeEncodedItem(localStorage.getItem('_olive_user'));

  if (getUserInfo) {
    if (type === 'email') {
      return getUserInfo.email;
    } else if (type === 'status') {
      return getUserInfo.status;
    } else if (type === 'user_type') {
      return getUserInfo.user_type;
    } else if (type === 'role') {
      return getUserInfo.role;
    } else if (type === 'firstName') {
      return getUserInfo.firstName;
    } else if (type === 'lastName') {
      return getUserInfo.lastName;
    } else if (type === 'name') {
      return getUserInfo.name;
    } else if (type === 'clientId') {
      return getUserInfo.clientId;
    } else if (type === 'orgId') {
      return getUserInfo.orgId;
    } else if (type === 'timeZone') {
      return getUserInfo.timeZone;
    } else if (type === 'templateFeature') {
      return getUserInfo.templateFeature;
    } else if (type === 'userRole') {
      return getUserInfo.userRole;
    } else if (type === 'id') {
      // return '6226c5789ae7dd51adefd4c2'; //Prince USERID
      // return '6203723dbda41c5e26d539aa'
      return getUserInfo.id;
    } else if (type === 'welcomemessage') {
      return getUserInfo.welcomemessage;
    }
  }

  return null;
};

export const isAdmin = () => {
  const getUserInfo = decodeEncodedItem(localStorage.getItem('_olive_user'));
  return getUserInfo.role == 'ADMIN';
};

export const decodeEncodedItem = decodedItem => {
  if (decodedItem !== '' && decodedItem !== undefined && decodedItem !== null) {
    decodedItem = JSON.parse(decodedItem);
    decodedItem = jwt.decode(decodedItem, process.env.REACT_APP_JWT_SECRET, 'HS512');
  }
  return decodedItem;
};

export const isColor = strColor => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

export const imageExists = (url, callback) => {
  var img = new Image();
  img.onload = function (error) {
    callback(true);
  };
  img.onerror = function (error) {
    callback(false);
  };
  img.src = url;
};

export const download = url => {
  const a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  a.rel = 'noreferrer';
  a.download = url.split('/').pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const RandomGenerator = length => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const delay = ms => {
  let timerObj = null;
  return () => {
    clearTimeout(timerObj);
    return new Promise(resolve => {
      timerObj = setTimeout(resolve, ms);
    });
  };
};

export function formatDateTo(dateString) {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const convertDateToUserTimeZone = date => {
  let dateTime = new Date(date);
  let timeZone = getUserItem('timeZone');
  return timeZone
    ? dateTime.toLocaleDateString('en-CA', { timeZone }) + ' / ' + dateTime.toLocaleTimeString('en-US', { timeZone })
    : dateTime.toLocaleDateString('en-CA') + ' / ' + dateTime.toLocaleTimeString('en-US');
};

export const splitFontFamilyAndStyle = font => {
  const fontSplit = font.split(' : ');

  return { fontFamily: fontSplit[0], fontStyle: fontSplit[1] };
};

export const mergeFontFamilyAndStyle = font => {
  const fontFamily = font.split('/')[4];
  const fontStyle = font.split('/')[5];
  if (fontStyle) {
    return fontFamily + ' : ' + fontStyle;
  }

  return fontFamily;
};

export const templateName = template => {
  if (!template) return '';
  const templateSplit = template.split('/');
  const lastItem = templateSplit[templateSplit.length - 1];

  const lastItemSplit = lastItem.split('.ntp');

  return lastItemSplit[0];
};

export const getRandomNumber = ({ min = 0, max }) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getProjectQueryData = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const from = urlParams.get('from');
  const module = urlParams.get('module');
  const slide = urlParams.get('slide');
  const screen = urlParams.get('screen');
  const action = urlParams.get('action');
  const checkPreview = urlParams.get('checkPreview');
  const hasShownMessageForAllMediaUrl = urlParams.get('hasShownMessageForAllMediaUrl');
  const currentLink = urlParams.get('currentLink');
  const requestId = urlParams.get('requestId');
  const currentActiveSlide = urlParams.get('currentActiveSlide');
  const currentActiveScreen = urlParams.get('currentActiveScreen');

  return {
    from,
    module,
    slide,
    screen,
    action,
    checkPreview,
    hasShownMessageForAllMediaUrl,
    currentLink,
    requestId,
    currentActiveSlide,
    currentActiveScreen,
  };
};

export const formatDate = date => {
  return new Date(date).toLocaleDateString('en-UK', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const checkAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const client = urlParams.get('client');

  return { token, client };
};

export const convertDateFormat = inputDate => {
  const date = new Date(inputDate);

  // Extracting day, month, and year
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getUTCFullYear();

  // Formatting the date in the desired format
  const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;

  return formattedDate;
};

export function formatDateUser(inputDate) {
  const currentDate = new Date();
  const updateDate = new Date(inputDate);

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate - updateDate;
  const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

  if (hoursDifference < 24) {
    if (hoursDifference < 2) {
      return 'updated an hour ago';
    } else {
      return `updated ${hoursDifference} hours ago`;
    }
  } else if (hoursDifference < 48) {
    return 'updated yesterday';
  } else {
    // Format the date using toLocaleDateString
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return updateDate.toLocaleDateString(undefined, options);
  }
}

export const getQueryData = query => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(query);
};

export const formatDateTime = dateTime => {
  // Create a Date object from the input string
  if (!dateTime) {
    return '-';
  }
  const inputDate = new Date(dateTime);

  // Format the date components
  const year = inputDate.getUTCFullYear();
  const month = inputDate.getUTCMonth() + 1; // Months are zero-based
  const day = inputDate.getUTCDate();
  const hours = inputDate.getUTCHours();
  const minutes = inputDate.getUTCMinutes();
  const seconds = inputDate.getUTCSeconds();

  // Format the date part as "YYYY-MM-DD"
  const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

  // Format the time part as "h:mm:ss A"
  const formattedTime = `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds} ${hours < 12 ? 'AM' : 'PM'}`;

  // Combine the formatted date and time
  const formattedResult = `${formattedDate} / ${formattedTime}`;
  return formattedResult;
};

export function getCurrencySymbol(currency) {
  switch (currency) {
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
    case 'AUD':
      return 'AU$';
    default:
      return currency;
  }
}

export function useQueryParam() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const checkStatus = status => {
  let title = '';
  let className = '';

  if (status.isDeleted) {
    className = 'action-status failed';
    title = 'Deleted';
  } else if (status.status === 'Active' || status.isActive) {
    className = 'action-status success';
    title = 'Active';
  } else if (status.status === 'InActive' || !status.isActive) {
    className = 'action-status pending';
    title = 'Inactive';
  }

  return { statusClassName: className, statusTitle: title };
};

export const isSuperadminDomain =
  window.location.hostname.split('.')[0] === 'www' ||
  window.location.hostname.split('.')[0] === 'localhost' ||
  window.location.hostname.split('.')[1] === 'vidayopro';

export const subDomainRegExp = /^[a-zA-Z][a-zA-Z0-9]+$/;

export const calculateSavings = (monthlyPrice, yearlyPrice) => {
  const monthlyCostForYear = parseFloat(monthlyPrice) * 12;
  const yearlyCost = parseFloat(yearlyPrice);

  if (!isNaN(monthlyCostForYear) && !isNaN(yearlyCost)) {
    const saving = monthlyCostForYear - yearlyCost;
    let savingPercentage = (saving / monthlyCostForYear) * 100;

    if (!Number.isInteger(savingPercentage)) {
      savingPercentage = savingPercentage.toFixed(2);
    }

    return savingPercentage;
  } else {
    return 0;
  }
};

export const dispatchStore = {
  dispatchShowContactSalesModalOnStorageExceeded: ({ title, message } = {}) =>
    store.dispatch({
      type: SHOW_CONTACT_SALES_MODAL,
      payload: {
        title: title || 'Storage Limit Reached',
        message:
          message ||
          'You have reached your storage limit. To increase your storage limit, please contact our support team for assistance and to discuss available options.',
      },
    }),
  showErrorLogReportModal: data => {
    store.dispatch({
      type: SHOW_ERROR_LOG_REPORT_MODAL,
      payload: {
        errorId: data.errorId,
        error: data.error,
      },
    });
  },
};

export const showContactSalesModalOnStorageExceeded = error => {
  const err = error.response?.data?.error;

  if (err?.message?.includes('Remaining storage')) {
    let message = '';

    if (err.lessThan10MB) {
      message =
        'Your uploaded file size is more that the remaining storage limit for your plan. Please fill the form below, and we will get in touch as soon as possible.';
    }

    dispatchStore.dispatchShowContactSalesModalOnStorageExceeded({ message });
  } else if (err?.includes('limit exceeded')) {
    dispatchStore.dispatchShowContactSalesModalOnStorageExceeded();
  }
};

export const urltoFile = (url, filename) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: 'image/jpeg' });
    });
};
export const pdfValidation = pdfFile => {
// export const pdfValidation = e => {
  // const pdfFile = e.target.files[0];
  const allowedExt = '.pdf'.split(',').map(data => data.trim());
  if (!allowedExt.find(ext => pdfFile.name.toLowerCase().endsWith(ext))) {
    return 'Please upload a pdf file';
  } else if (pdfFile.size > 10485760) {
    return 'Uploaded file should not exceed 10 mb';
  } else {
    return '';
  }
};

export const concatenatText = data => {
  let concatenatedText = '';
  let imageIds = [];
  data.page_details?.forEach((pdf, index) => {
    if (pdf.type === 'text' || pdf.type === 'title' || pdf.type === 'list') {
      concatenatedText += pdf.content;
      if (index < data.page_details.length - 1) {
        concatenatedText += '\n\n'; // Add double newline after each content except the last one
      }
    } else if ((pdf.type === 'figure' || pdf.type === 'table') && pdf.content.assetId) {
      imageIds.push(pdf.content.assetId);
    }
  });
  return { concatenatedText, imageIds };
  // return concatenatedText;
};

export const getSlideId = slides => {
  const lastSlide = slides[slides.length - 1].slideId;

  const slideNumber = lastSlide.replace('SL0', '');
  const slideNumberSplit = slideNumber.split('SL')[1];

  let data;
  if (slideNumberSplit) data = parseInt(slideNumberSplit) + 1;
  else data = parseInt(slideNumber) + 1;

  const newSlideId = data < 10 ? 'SL0' + data : 'SL' + data;
  const newSlideNumber = newSlideId.replace('SL', '');

  return { data, newSlideId, newSlideNumber };
};

export const deriveModuleSlideScreenId = project => {
  const { from, module, action, slide, screen, currentLink, requestId } = getProjectQueryData();
  let moduleId = 'M01';
  let slideId = 'SL01';
  let screenId = '01';

  if (from === 'edit-projects' || from === 'edit-projects-add' || currentLink === 'content' || currentLink === 'segments') {
    moduleId = module;
    slideId = slide;
    screenId = screen;
  } else {
    if (project?.draft?.ocr?.data) {
      const projectOcrDraft = project?.draft?.ocr;
      const draftData = JSON.parse(projectOcrDraft.data);
      const activeOcrDraftData = projectOcrDraft.activeOcr ? draftData[projectOcrDraft.activeOcr] : Object.values(draftData)[0];

      if (activeOcrDraftData?.module) {
        moduleId = activeOcrDraftData.module;

        const projectModules = project?.ProjectData?.[project.ProjectId]?.Modules?.[activeOcrDraftData.module];

        if (projectModules) {
          const slides = Object.keys(projectModules.slides).map(slideId => ({ slideId }));

          if (slides) {
            const { newSlideId, newSlideNumber } = getSlideId(slides);

            slideId = newSlideId;
            screenId = newSlideNumber;
          }
        }
      }
    }
  }

  return { originalQueryData: { from, module, action, slide, screen, currentLink, requestId }, moduleId, slideId, screenId };
};

export const getNewModuleId = projectModules => {
  const lastModuleId = projectModules[projectModules.length - 1]?.[0];

  const lastModuleNumber = lastModuleId.replace('M0', '');
  const lastModuleNumberSplit = lastModuleNumber.split('M')[1];

  let data;
  if (lastModuleNumberSplit) data = parseInt(lastModuleNumberSplit) + 1;
  else data = parseInt(lastModuleNumber) + 1;

  const newModuleId = data < 10 ? 'M0' + data : 'M' + data;

  return { newModuleId, data };
};
