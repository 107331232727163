export const HEADERFONT = '[H]';
export const PARAGRAPHFONT = '[P]';
export const BULLETFONT = '[B]';
export const FONT_URL = 'https://oliveproduction-automation-storages.s3.amazonaws.com/Fonts/';
export const FONTFAMILY = 'Exo-Bold';
export const FONTSTYLE = 'Exo-Bold.otf';
export const INIT_OST_FONT = FONT_URL + 'Exo-Bold/Exo-Bold.otf';
export const FONT_SIZE_H = 30;
export const FONT_SIZE_P = 30;
export const COUNT = 0;
export const TextColor = '#ffffff';
export const BgColor = '#151589';

// dashboard

export const ALLCATEGORY = 'All Category';
export const LISTSTATUSALL = 'All';
export const TEXT_SIZES = [
  { id: 1, name: '30' },
  { id: 2, name: '34' },
  { id: 3, name: '36' },
  { id: 4, name: '40' },
  { id: 6, name: '48' },
  { id: 7, name: '54' },
];
export const VIDEO_LIMIT = 2;
export const IMAGE_LIMIT = 3;
export const ILLUSTRATION_LIMIT = 4;

export const RENDER_NAME = 'temprender';
export const CLUSTER_NAME = 'contentprok8scluster';

export const BILLING_DURATION = [
  { value: 'day', label: 'Daily' },
  { value: 'month', label: 'Monthly' },
  { value: 'year', label: 'Annual' },
];

export const CURRENCY_LIST = [
  { value: 'GBP', label: 'POUND(£)', symbol: '£' },
  { value: 'USD', label: 'USD($)', symbol: '$' },
  { value: 'EUR', label: 'EURO(€)', symbol: '€' },
  { value: 'AUD', label: 'AUD(A$)', symbol: 'A$' },
];

export const FREE_PLAN = 'FREE';
export const TRIAL_PLAN = 'TRIAL';
export const ENTERPRISE_PLAN = 'ENTERPRISE';
export const B2C_PLAN = 'B2C';

export const FETCHING = 'FETCHING';
export const SAVING = 'SAVING';

export const VIDEO_TRIM_DURATION = 15;
