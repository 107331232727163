import { combineReducers } from 'redux';
import previewReducer from './previewReducer';
import projectReducer from './projectReducer';
import genericReducer from './genericReducer';
import remainingFeaturesReducer from './remainingFeatures';
import errorLogReducer from './errorLogReducer';
import pdfUploadReducer from './pdfUploadReducer';
import saveAsDraftReducer from './saveAsDraftReducer';

const reducers = combineReducers({
  project: projectReducer,
  preview: previewReducer,
  genericStore: genericReducer,
  remainingFeatures: remainingFeaturesReducer,
  errorLog: errorLogReducer,
  pdfUpload: pdfUploadReducer,
  saveDraft: saveAsDraftReducer,
});

export default reducers;
